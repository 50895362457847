<template>
  <div class="container">
    <b-row>
      <div class="centered">
        <SectionTitleIcon />
        <h1 class="title-text">Napísali o nás</h1>
      </div>
      <AboutNapisaliONas />
    </b-row>
  </div>
</template>

<script>
import SectionTitleIcon from "/assets/icons/sectionTitleImg.svg?inline";
export default {
  components: {
    SectionTitleIcon,
    AboutNapisaliONas: () => import("/components/About/AboutNapisaliONas.vue"),
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin: 100px auto;
  margin-bottom: 50px;
  padding-bottom: 0;
  @media only screen and (max-width: 575px) {
    margin: 50px auto;
  }
}
.centered {
  z-index: 1000;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 2rem;
  h1 {
    margin: auto 0 auto 10px;
  }
  @media only screen and (max-width: 575px) {
    text-align: center;
    img {
      width: 64px;
    }
    h1 {
      margin: 0 0 0 10px;
      font-size: 26px;
    }
  }
}

.box-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.box {
  height: 7rem;
  width: 220px;
  display: flex;
  justify-content: center;
  img {
    place-self: center center;
    width: auto;
    height: auto;
    margin: 1rem;
  }
}
</style>
