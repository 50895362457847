<template>
  <div class="container">
    <div class="sm-center">
      <div class="title">
        <SectionTitleIcon />
        <h1>O programe</h1>
      </div>
      <p class="text">
        Zmudri do škôl je bezplatný rozvojový program pre učiteľov a žiakov od
        8. ročníka základných škôl a všetky typy stredných škôl.
        <strong>
          Tento program je nadstavbou k bežnému používaniu našich voľne
          dostupných video kurzov.
        </strong>
        Vďaka účasti získate množstvo výhod, ako účasť na workshopoch pre
        učiteľov, možnosť merať posun v postojoch žiakov, podporu od nášho tímu,
        certifikát a mnoho ďalších.
      </p>
      <div class="text">
        <strong> Príklady časovej náročnosti programu: </strong>
        <br />
        <em> 1. Vybrali ste si modul E, ktorý obsahuje 10 videokurzov. </em>
        <ul>
          <li>
            <span>
              Na jeden školský polrok si pre program musíte vyhradiť 10
              vyučovacích hodín.
            </span>
          </li>
        </ul>
        <em>
          2. Vybrali ste si moduly A a E, ktoré obsahujú 9 a 10 videokurzov.
        </em>
        <ul>
          <li>
            <span>
              Na jeden školský polrok si pre program musíte vyhradiť 19
              vyučovacích hodín.
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div class="main-img-container">
      <img class="main-img" src="/section2.png" />
    </div>
  </div>
</template>

<script>
import SectionTitleIcon from "/assets/icons/sectionTitleImg.svg?inline";

export default {
  components: {
    SectionTitleIcon,
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 2.5rem;

  margin: 6.25rem auto;
  padding-bottom: 0;
}
.title {
  display: inline-flex;
  margin-bottom: 2.5rem;
  h1 {
    margin: auto 0 auto 0.625rem;
  }
}
ul {
  list-style-type: "\27A1";
}
.text {
  line-height: 1.75rem;
  letter-spacing: 0;
  color: rgba(43, 46, 74, 0.6);

  margin-bottom: 1.875rem;
  max-width: 30rem;

  li > span {
    margin-left: 0.625rem;
  }
}

.main-img-container {
  display: flex;
  align-items: center;

  @media only screen and (max-width: 999px) {
    display: none;
  }
}

.main-img {
  max-width: 100%;
  max-height: 100%;
}

@media only screen and (max-width: 999px) {
  .container {
    display: block;
    text-align: center;
    padding-bottom: 1.25rem;
  }
  .sm-center {
    text-align: center;
  }
  .text {
    margin: 1.875rem auto;
  }
}
@media only screen and (max-width: 575px) {
  .container {
    margin: 2.5rem auto;
  }
  .title {
    img {
      width: 4rem;
    }
    h1 {
      font-size: 1.625rem;
    }
  }
}
</style>
