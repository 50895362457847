<template>
  <div class="background">
    <div class="container" id="priebeh-programu">
      <div class="title">
        <SectionTitleIcon />
        <h1>{{ $t("program-progress") }}</h1>
      </div>
      <div class="content d-none d-xl-block">
        <div class="first-cards cards">
          <Card :cardData="card" v-for="(card, i) in upperCards" :key="i" />
        </div>
        <div class="middle-line"></div>
        <div class="second-cards cards">
          <Card :cardData="card" v-for="(card, i) in lowerCards" :key="i" />
        </div>
      </div>
      <div class="d-xl-none row">
        <div
          class="col-12 col-sm-6 my-3"
          v-for="(card, i) in mobileCards"
          :key="i"
        >
          <Card class="" :cardData="card" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "/components/DoSkol/DoSkolPriebehProgramuCard";
import SectionTitleIcon from "/assets/icons/sectionTitleImg.svg?inline";
export default {
  components: {
    Card,
    SectionTitleIcon,
  },
  data() {
    return {
      upperCards: [
        {
          id: 1,
          position: "bottom",
          color: "rgba(255, 170, 0, 1)",
          colorSecondary: "rgba(255, 170, 0, .1)",
          date: "November-December",
          text: "Vyplníte prihlášku do programu. Vyberiete si 1 tematický modul pre 1 triedu. Prihláška je záväzná a miesta v programe obmedzené. ",
        },
        {
          id: 3,
          position: "bottom",
          color: "rgba(255, 170, 0, 1)",
          colorSecondary: "rgba(255, 170, 0, .1)",
          date: "Január - Február",
          text: "Ak budete vybraní do programu, absolvujete úvodný online webinár.",
        },
        {
          id: 5,
          position: "bottom",
          color: "rgba(251, 37, 100, 1)",
          colorSecondary: "rgba(251, 37, 100, .1)",
          date: "Február",
          text: "Môžete začať s programom. Na vašich hodinách použijete video kurzy s materiálmi z tematického modulu, ktorý si vyberiete.",
        },
        {
          id: 7,
          position: "bottom",
          color: "rgba(251, 37, 100, 1)",
          colorSecondary: "rgba(251, 37, 100, .1)",
          date: "Február - Jún",
          text: "Počas trvania programu môžete po vzájomnej dohode využiť možnosť našej návštevy na škole a odučenia hodiny za vás.",
        },
        {
          id: 9,
          position: "bottom",
          color: "rgba(1, 101, 255, 1)",
          colorSecondary: "rgba(1, 101, 255, .1)",
          date: "Jún",
          text: "Na záver nám vyplníte dotazník so spätnou väzbou.",
        },
      ],
      lowerCards: [
        {
          id: 2,
          position: "top",
          color: "rgba(255, 170, 0, 1)",
          colorSecondary: "rgba(255, 170, 0, .1)",
          date: "Polovica Decembra",
          text: "Dozviete sa výsledky výberového procesu.",
        },
        {
          id: 4,
          position: "top",
          color: "rgba(251, 37, 100, 1)",
          colorSecondary: "rgba(251, 37, 100, .1)",
          date: "Február",
          text: "Pred začatím programu vaši žiaci vyplnia postojové dotazníky.",
        },
        {
          id: 6,
          position: "top",
          color: "rgba(251, 37, 100, 1)",
          colorSecondary: "rgba(251, 37, 100, .1)",
          date: "Február - Jún",
          text: "(Dobrovoľné) Môžete absolvovať sériu vzdelávacích workshopov pre učiteľov na tému “Ako zlepšovať kritické myslenie u svojich študentov”.",
        },
        {
          id: 8,
          position: "top",
          color: "rgba(1, 101, 255, 1)",
          colorSecondary: "rgba(1, 101, 255, .1)",
          date: "Jún",
          text: "Na záver programu vyplnia vaši žiaci postojové dotazníky, na základe ktorých zistíte ich posun.",
        },
        {
          id: 10,
          position: "top",
          color: "rgba(1, 101, 255, 1)",
          colorSecondary: "rgba(1, 101, 255, .1)",
          date: "Polovica Júna",
          text: "Po úspešnom absolvovaní dostanete vy aj vaša škola certifikát a žiaci diplomy.",
        },
      ],
      mobileCards: [
        {
          id: 1,
          position: "bottom",
          color: "rgba(255, 170, 0, 1)",
          colorSecondary: "rgba(255, 170, 0, .1)",
          date: "November-December",
          text: "Vyplníte prihlášku do programu. Vyberiete si 1 tematický modul pre 1 triedu. Prihláška je záväzná a miesta v programe obmedzené. ",
        },
        {
          id: 2,
          position: "top",
          color: "rgba(255, 170, 0, 1)",
          colorSecondary: "rgba(255, 170, 0, .1)",
          date: "Polovica Decembra",
          text: "Dozviete sa výsledky výberového procesu.",
        },
        {
          id: 3,
          position: "bottom",
          color: "rgba(255, 170, 0, 1)",
          colorSecondary: "rgba(255, 170, 0, .1)",
          date: "Január - Február",
          text: "Ak budete vybraní do programu, absolvujete úvodný online webinár.",
        },
        {
          id: 4,
          position: "top",
          color: "rgba(251, 37, 100, 1)",
          colorSecondary: "rgba(251, 37, 100, .1)",
          date: "Február",
          text: "Pred začatím programu vaši žiaci vyplnia postojové dotazníky.",
        },
        {
          id: 5,
          position: "bottom",
          color: "rgba(251, 37, 100, 1)",
          colorSecondary: "rgba(251, 37, 100, .1)",
          date: "Február",
          text: "Môžete začať s programom. Na vašich hodinách použijete video kurzy s materiálmi z tematického modulu, ktorý si vyberiete.",
        },
        {
          id: 6,
          position: "top",
          color: "rgba(251, 37, 100, 1)",
          colorSecondary: "rgba(251, 37, 100, .1)",
          date: "Február - Jún",
          text: "(Dobrovoľné) Môžete absolvovať sériu vzdelávacích workshopov pre učiteľov na tému “Ako zlepšovať kritické myslenie u svojich študentov”.",
        },
        {
          id: 7,
          position: "bottom",
          color: "rgba(251, 37, 100, 1)",
          colorSecondary: "rgba(251, 37, 100, .1)",
          date: "Február - Jún",
          text: "Počas trvania programu môžete po vzájomnej dohode využiť možnosť našej návštevy na škole a odučenia hodiny za vás.",
        },
        {
          id: 8,
          position: "top",
          color: "rgba(1, 101, 255, 1)",
          colorSecondary: "rgba(1, 101, 255, .1)",
          date: "Jún",
          text: "Na záver programu vyplnia vaši žiaci postojové dotazníky, na základe ktorých zistíte ich posun.",
        },
        {
          id: 9,
          position: "bottom",
          color: "rgba(1, 101, 255, 1)",
          colorSecondary: "rgba(1, 101, 255, .1)",
          date: "Jún",
          text: "Na záver nám vyplníte dotazník so spätnou väzbou.",
        },
        {
          id: 10,
          position: "top",
          color: "rgba(1, 101, 255, 1)",
          colorSecondary: "rgba(1, 101, 255, .1)",
          date: "Polovica Júna",
          text: "Po úspešnom absolvovaní dostanete vy aj vaša škola certifikát a žiaci diplomy.",
        },
      ],
      mobileBreakPoint: 575,
      isMobile: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.background {
  background: rgba(246, 250, 252, 1);
}
.container {
  margin: 100px auto;
  margin-bottom: 30px;
  padding: 120px 0px 120px 0px;
  text-align: center;
}
.title {
  display: inline-flex;
  margin-bottom: 40px;
  h1 {
    margin: auto 0 auto 0.625rem;
  }
}
h1 {
  margin: 0 auto;
}
.content .cards {
  display: flex;
  justify-content: space-between;
}

.middle-line {
  background: linear-gradient(
    90.02deg,
    #fdb82b 0.67%,
    #fa497e 56.41%,
    #2b7eff 106.63%
  );
  height: 2px;
}

@media only screen and (max-width: 1199px) {
  .content {
    display: flex;
    justify-content: space-around;
    position: relative;
  }
  .cards {
    display: grid !important;
    width: 310px;
    margin: 10px;
  }
  .middle-line {
    display: none;
  }
}
@media only screen and (max-width: 575px) {
  .container {
    padding: 70px 0 50px;
  }
  .title {
    img {
      width: 64px;
    }
    h1 {
      font-size: 26px;
    }
  }
  .content {
    display: block;
    position: relative;
  }
  .cards {
    display: block !important;
    width: 100%;
    margin: 0;
  }
  .middle-line {
    display: none;
  }
}
</style>
