<template>
  <div class="container">
    <b-row>
      <div class="centered">
        <SectionTitleIcon />
        <h1 class="title-text">Zapojené Zmudri školy</h1>
      </div>
    </b-row>
    <b-row>
      <div class="centered">
        <iframe
          class="iframe-1"
          title=""
          aria-label="Map"
          id="datawrapper-chart-D2yLw"
          src="https://datawrapper.dwcdn.net/D2yLw/1/"
          scrolling="no"
          frameborder="0"
          style="border: none"
          width="1100"
          height="600"
          data-external="1"
        ></iframe>
        <iframe
          class="iframe-2"
          title=""
          aria-label="Map"
          id="datawrapper-chart-D2yLw"
          src="https://datawrapper.dwcdn.net/D2yLw/1/"
          scrolling="no"
          frameborder="0"
          style="border: none"
          width="800"
          height="400"
          data-external="1"
        ></iframe>
        <iframe
          class="iframe-3"
          title=""
          aria-label="Map"
          id="datawrapper-chart-D2yLw"
          src="https://datawrapper.dwcdn.net/D2yLw/1/"
          scrolling="no"
          frameborder="0"
          style="border: none"
          width="500"
          height="250"
          data-external="1"
        ></iframe>
        <iframe
          class="iframe-4"
          title=""
          aria-label="Map"
          id="datawrapper-chart-D2yLw"
          src="https://datawrapper.dwcdn.net/D2yLw/1/"
          scrolling="no"
          frameborder="0"
          style="border: none"
          width="300"
          height="200"
          data-external="1"
        ></iframe>
      </div>
    </b-row>
  </div>
</template>

<script>
import SectionTitleIcon from "/assets/icons/sectionTitleImg.svg?inline";
export default {
  components: {
    SectionTitleIcon,
  },
};
</script>

<style lang="scss" scoped>
.iframe-1 {
  @media only screen and (max-width: 1000px) {
    display: none;
  }
}

.iframe-2 {
  @media only screen and (max-width: 600px) {
    display: none;
  }

  @media only screen and (min-width: 1001px) {
    display: none;
  }
}

.iframe-3 {
  @media only screen and (min-width: 601px) {
    display: none;
  }

  @media only screen and (max-width: 400px) {
    display: none;
  }
}

.iframe-4 {
  @media only screen and (min-width: 401px) {
    display: none;
  }
}

.container {
  margin: 100px auto;
  margin-bottom: 50px;
  padding-bottom: 0;
  @media only screen and (max-width: 575px) {
    margin: 50px auto;
  }
}
.centered {
  z-index: 1000;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 2rem;
  h1 {
    margin: auto 0 auto 10px;
  }
  @media only screen and (max-width: 575px) {
    text-align: center;
    img {
      width: 64px;
    }
    h1 {
      margin: 0 0 0 10px;
      font-size: 26px;
    }
  }
}

.box-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.box {
  height: 7rem;
  width: 220px;
  display: flex;
  justify-content: center;
  img {
    place-self: center center;
    width: auto;
    height: auto;
    margin: 1rem;
  }
}
</style>
