<template>
  <div class="container" id="vyhody-programu">
    <div class="title">
      <SectionTitleIcon />
      <h1>{{ $t("do-skol.benefits.program-benefits") }}</h1>
    </div>
    <div class="row">
      <div class="benefit col-12 col-md-6 col-lg-4">
        <HeatIcon class="d-block m-auto ml-md-4" />

        <div>
          <p class="subtitle">{{ $t("do-skol.benefits.for-teacher") }}</p>

          <div class="d-flex" v-for="benefit of teacherBenefits" :key="benefit">
            <CheckmarkOrangeIcon class="check-icon" />
            <p class="description">{{ benefit }}</p>
          </div>
        </div>
      </div>

      <div class="benefit col-12 col-md-6 col-lg-4">
        <StudyIcon class="d-block m-auto ml-md-4" />

        <div>
          <p class="subtitle">{{ $t("do-skol.benefits.for-student") }}</p>

          <div class="d-flex" v-for="benefit of studentBenefits" :key="benefit">
            <CheckmarkOrangeIcon class="check-icon" />
            <p class="description">{{ benefit }}</p>
          </div>
        </div>
      </div>

      <div class="benefit col-12 col-md-6 col-lg-4 m-0 m-md-auto m-lg-0">
        <PreVasuSkoluIcon class="d-block m-auto ml-md-4" />

        <div>
          <p class="subtitle">{{ $t("do-skol.benefits.for-school") }}</p>

          <div class="d-flex" v-for="benefit of schoolBenefits" :key="benefit">
            <CheckmarkOrangeIcon class="check-icon" />
            <p class="description">{{ benefit }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SectionTitleIcon from "/assets/icons/sectionTitleImg.svg?inline";
import CheckmarkOrangeIcon from "/assets/icons/checkmarkOrangeIcon.svg?inline";
import HeatIcon from "/assets/icons/heatIcon.svg?inline";
import StudyIcon from "/assets/icons/studyIcon.svg?inline";
import PreVasuSkoluIcon from "/assets/icons/preVasuSkolu.svg?inline";
export default {
  components: {
    SectionTitleIcon,
    CheckmarkOrangeIcon,
    HeatIcon,
    StudyIcon,
    PreVasuSkoluIcon,
  },

  methods: {
    getListTranslations(section) {
      return Object.keys(
        this.$i18n.messages[this.$i18n.locale]["do-skol"]["benefits"]
      )
        .filter((key) => new RegExp(`^${section}-\\d+$`).test(key))
        .map((key) => this.$t("do-skol.benefits." + key));
    },
  },

  created() {
    this.teacherBenefits = this.getListTranslations("teacher");
    this.studentBenefits = this.getListTranslations("student");
    this.schoolBenefits = this.getListTranslations("school");
  },
};
</script>
<style lang="scss" scoped>
.container {
  padding: 6.25rem 0;
}
.title {
  display: flex;
  align-items: center;
  margin: 0 auto 2.5rem;
  width: fit-content;
  h1 {
    margin: 0 0 0 0.625rem;
  }
  @media only screen and (max-width: 575px) {
    text-align: center;
    img {
      width: 4rem;
    }
    h1 {
      font-size: 1.625rem;
    }
  }
}
@media only screen and (max-width: 575px) {
  .container {
    padding: 9.375rem 0 6.25rem;
  }
}

.benefit {
  padding: 0 2.5rem;

  .subtitle {
    margin-left: 0;
    font-size: 1.5rem;
    font-family: "Boing", sans-serif;
    line-height: 3.4375rem;
    font-weight: 900 !important;
    color: var(--a-color-black);
  }
  .description {
    line-height: 1.875rem;
    color: #2b2e4a99;
  }
  .check-icon {
    height: 0.75rem;
    margin: 0.625rem 0.625rem 0 0;
    flex-shrink: 0;
  }
}
</style>
