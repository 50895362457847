<template>
  <div class="background">
    <div class="container">
      <div class="hero-big d-lg-flex d-none">
        <div>
          <h1 class="title title-first">{{ $t("teachers") }},</h1>
          <h1 class="title">{{ $t("move-forward") }}!</h1>
          <i18n path="do-skol-hero-info" tag="p" class="text">
            <template v-slot:email>
              <a href="mailto:skoly@zmudri.sk">skoly@zmudri.sk</a>
            </template>
            <template v-slot:zmudri-link>
              <a href="https://zmudri.sk">zmudri.sk</a>
            </template>
          </i18n>
        </div>
        <div class="img-w-text">
          <img src="/sec2.png" />
          <h1 class="hero-text">
            <a href="mailto:skoly@zmudri.sk">skoly@zmudri.sk</a>
          </h1>
        </div>
      </div>
      <div class="hero-small d-lg-none d-block">
        <div class="img-w-text-mobile">
          <img src="/sec2.png" alt="" />
          <h1 class="hero-text">
            <a href="mailto:skoly@zmudri.sk">skoly@zmudri.sk</a>
          </h1>
        </div>
        <div>
          <h1 class="title title-first">{{ $t("teachers") }},</h1>
          <h1 class="title">{{ $t("move-forward") }}!</h1>
          <i18n path="do-skol-hero-info" tag="p" class="text">
            <template v-slot:email>
              <a href="mailto:skoly@zmudri.sk">skoly@zmudri.sk</a>
            </template>
            <template v-slot:zmudri-link>
              <a href="https://zmudri.sk">zmudri.sk</a>
            </template>
          </i18n>
        </div>
      </div>
    </div>
    <div class="splitter"></div>
  </div>
</template>
<script>
export default {
  methods: {
    loginToProgram() {
      window.open("https://forms.gle/HUfBuWjxwmhdVPFy7");
    },
  },
};
</script>
<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.background {
  background: linear-gradient(180deg, rgba(246, 250, 252, 0) 0%, #f6fafc 100%);
}

.container {
  margin: 20px auto;
  margin-bottom: 0;
  padding-bottom: 0;

  a {
    color: #0165ff;
    font-size: inherit;
  }
}

.hero-text a {
  color: white;
}

.hero-big {
  width: 100%;
  height: 495px;
  display: flex;
  justify-content: space-between;
}
.title-first {
  margin-top: 100px;

  @media only screen and (max-width: 1199px) {
    margin-top: 0;
  }
}
.title {
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 6px;
}
.text {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  max-width: 370px;
  color: rgba(43, 46, 74, 0.6);
  margin: 24px 0 40px 0px;
  line-height: 24px;
}
.log-in-button {
  height: 56px;
  width: 267px;
}
.img-w-text {
  position: relative;
  height: 100%;
  max-width: calc(100% - 492px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  img {
    margin-top: auto;
    display: block;
    width: auto;
    max-height: 100%;
    max-width: 100%;
  }

  .hero-text {
    position: absolute;
    margin-bottom: 1rem;
    left: 3rem;
    font-weight: 600;
    text-align: center;

    @media only screen and (max-width: 1399px) {
      margin-bottom: 0.75rem;
      left: 2rem;
    }

    @media only screen and (max-width: 1199px) {
      margin-bottom: 1rem;
      left: 2rem;
      line-height: 1.5rem;
      font-size: 1.5rem;
    }
  }
}

.img-w-text-mobile {
  position: relative;
  width: 100%;
  img {
    width: 100%;
  }

  .hero-text {
    position: absolute;
    bottom: 0;
    font-weight: 600;

    @media screen and (max-width: 992px) {
      margin-bottom: 1rem;
      left: 3rem;
    }

    @media only screen and (max-width: 768px) {
      margin-bottom: 1.25rem;
      left: 3rem;
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
    @media only screen and (max-width: 576px) {
      margin-bottom: 1rem;
      left: 2.5rem;
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
    @media only screen and (max-width: 484px) {
      margin-bottom: 0.75rem;
      left: 1.25rem;
      font-size: 1.25rem;
      line-height: 1.25rem;
    }
    @media only screen and (max-width: 380px) {
      margin-bottom: 0.75rem;
      left: 1.25rem;
      font-size: 1rem;
      line-height: 1rem;
    }
  }
}
.splitter {
  height: 2px !important;
  width: 100vw;
  background: rgba(243, 243, 243, 1);
  border: none;
}

@media only screen and (max-width: 1199px) {
  .hero-big {
    display: flex;
    height: fit-content;
  }
  .img-w-text {
    p {
      bottom: 2px;
      left: 80px;
      font-size: 11px;
    }
  }
}
@media screen and (max-width: 992px) {
  .container {
    padding: 0;
    margin-top: 0;
  }
  .hero-small {
    display: block;
    padding: 0 0 20px;
    text-align: center;
  }
  .text {
    margin: 30px auto;
  }
  .img-w-text-mobile {
    margin-bottom: 2.5rem;
  }

  img {
    width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@media screen and (max-width: 575px) {
  .img-w-text-mobile {
    margin-bottom: 0.625rem;
  }
}
</style>
