/*eslint no-mixed-spaces-and-tabs: "error"*/
<template>
  <div class="container">
    <div class="row">
      <div class="col-sm">
        <div class="center">
          <img class="image" src="/kritickeMyslenie.png" />
        </div>
      </div>
      <div class="col-sm">
        <div class="center">
          <h1 class="title-text">
            {{ $t("registration-only-2024-workshop") }}
            <a href="mailto:skoly@zmudri.sk">skoly@zmudri.sk</a>
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    loginToProgram() {
      window.open("https://forms.gle/HUfBuWjxwmhdVPFy7");
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  margin: 20px auto;
}
.image {
  height: 485px;
  margin-bottom: auto;
  margin-top: 2px;
  margin-left: 55px;
}
.title-text {
  text-align: left;
  max-width: 80%;
  min-width: 80%;
  line-height: 48px;
  font-weight: 700;
  font-size: 38px;
  a {
    color: #0165ff;
    font-size: inherit;
  }
}
.term {
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #fb2564;
}
.button {
  margin-top: 12px;
  height: 56px;
  width: 267px;
  radius: 100px;
}
.center {
  margin-bottom: auto;
  margin-top: auto;
}
.col-sm {
  display: flex;
  flex-direction: column;
  align-items: left;
}
@media only screen and (max-width: 767px) {
  .image {
    display: none;
  }
  .row {
    display: block;
  }
  .center {
    text-align: center;
    .title-text {
      margin: 0 auto 20px;
      text-align: center !important;
    }
    p {
      text-align: center;
    }
  }
}
@media only screen and (max-width: 991px) {
  .image {
    text-align: left;
    margin: 30;
  }
}
@media only screen and (min-width: 1900px) {
  .title-text {
    max-width: 100%;
    max-height: 100%;
  }
  .image {
    height: 700px;
    margin: 30px 30px 30px 0px;
  }
}
</style>
