<template>
  <div class="container" id="faq">
    <div class="title">
      <SectionTitleIcon />
      <h1 class="title-text">Často kladené otázky</h1>
    </div>

    <div class="custom-accordion">
      <div class="custom-accodrion-card">
        <div
          class="custom-accodrion-button d-flex align-items-center"
          :class="{ opened: openedAccordion === 1 }"
          @click="openAcordion(1)"
        >
          <div class="icon">
            <div
              class="circle-plus closed"
              :class="{ opened: openedAccordion === 1 }"
            >
              <div class="circle">
                <div class="horizontal"></div>
                <div class="vertical"></div>
              </div>
            </div>
          </div>

          Je program záväzný?
        </div>
        <div
          class="custom-accodrion-collapse-wrapper"
          ref="collapse-wrapper-a1"
        >
          <div class="custom-accodrion-collapse" ref="collapse-a1">
            <b-card-body>
              <b-card-text
                >Áno, prihlásením do programu sa zaväzujete absolvovať ho tak,
                ako je uvedené v podmienkach. Pred začatím programu podpíšeme
                spolu dohodu o spolupráci. Preto prosím dôkladne zvážte svoje
                časové kapacity a možnosti, keďže miesta v programe sú
                obmedzené.</b-card-text
              >
            </b-card-body>
          </div>
        </div>
      </div>

      <div class="custom-accodrion-card">
        <div
          class="custom-accodrion-button d-flex align-items-center"
          :class="{ opened: openedAccordion === 2 }"
          @click="openAcordion(2)"
        >
          <div class="icon">
            <div
              class="circle-plus closed"
              :class="{ opened: openedAccordion === 2 }"
            >
              <div class="circle">
                <div class="horizontal"></div>
                <div class="vertical"></div>
              </div>
            </div>
          </div>

          Je program bezplatný?
        </div>
        <div
          class="custom-accodrion-collapse-wrapper"
          ref="collapse-wrapper-a2"
        >
          <div class="custom-accodrion-collapse" ref="collapse-a2">
            <b-card-body>
              <b-card-text>Áno, program je úplne bezplatný.</b-card-text>
            </b-card-body>
          </div>
        </div>
      </div>

      <div class="custom-accodrion-card">
        <div
          class="custom-accodrion-button d-flex align-items-center"
          :class="{ opened: openedAccordion === 3 }"
          @click="openAcordion(3)"
        >
          <div class="icon">
            <div
              class="circle-plus closed"
              :class="{ opened: openedAccordion === 3 }"
            >
              <div class="circle">
                <div class="horizontal"></div>
                <div class="vertical"></div>
              </div>
            </div>
          </div>

          Aký je rozdiel medzi týmto programom a bežným používaním kurzov na
          zmudri.sk?
        </div>
        <div
          class="custom-accodrion-collapse-wrapper"
          ref="collapse-wrapper-a3"
        >
          <div class="custom-accodrion-collapse" ref="collapse-a3">
            <b-card-body>
              <b-card-text>
                Video kurzy v rámci programu Zmudri do škôl vo vami zvolenom
                module budete musieť absolvovať za jeden školský polrok. Pri
                bežnom používaní videí zo zmudri.sk si videá môžete používať aj
                v iných triedach alebo na iných predmetoch tak, ako vám to
                vyhovuje.
              </b-card-text>
            </b-card-body>
          </div>
        </div>
      </div>

      <div class="custom-accodrion-card">
        <div
          class="custom-accodrion-button d-flex align-items-center"
          :class="{ opened: openedAccordion === 4 }"
          @click="openAcordion(4)"
        >
          <div class="icon">
            <div
              class="circle-plus closed"
              :class="{ opened: openedAccordion === 4 }"
            >
              <div class="circle">
                <div class="horizontal"></div>
                <div class="vertical"></div>
              </div>
            </div>
          </div>

          Môžem sa zapojiť do programu s viacerými triedami?
        </div>
        <div
          class="custom-accodrion-collapse-wrapper"
          ref="collapse-wrapper-a4"
        >
          <div class="custom-accodrion-collapse" ref="collapse-a4">
            <b-card-body>
              <b-card-text
                >Môžete. Je však potrebné, aby ste jeden celý modul absolvovali
                v jednej triede. Rovnaký modul môžete absolvovať aj v druhej
                triede za rovnakých podmienok.</b-card-text
              >
            </b-card-body>
          </div>
        </div>
      </div>

      <div class="custom-accodrion-card">
        <div
          class="custom-accodrion-button d-flex align-items-center"
          :class="{ opened: openedAccordion === 5 }"
          @click="openAcordion(5)"
        >
          <div class="icon">
            <div
              class="circle-plus closed"
              :class="{ opened: openedAccordion === 5 }"
            >
              <div class="circle">
                <div class="horizontal"></div>
                <div class="vertical"></div>
              </div>
            </div>
          </div>

          Nemôžem program absolvovať v inom časovom období?
        </div>
        <div
          class="custom-accodrion-collapse-wrapper"
          ref="collapse-wrapper-a5"
        >
          <div class="custom-accodrion-collapse" ref="collapse-a5">
            <b-card-body>
              <b-card-text>
                Program je ohraničený na školský polrok. Ak vám vyhovuje prebrať
                niektorý z modulov až v 1.polroku školského roka 2023/2024,
                počkajte si na prihlasovanie v júni 2023. Môžete nám poslať
                e-mail na
                <a class="link" href="mailto:skoly@zmudri.sk">
                  skoly@zmudri.sk
                </a>
                a my vás upozorníme, keď bude prihlasovanie spustené.
              </b-card-text>
            </b-card-body>
          </div>
        </div>
      </div>

      <div class="custom-accodrion-card">
        <div
          class="custom-accodrion-button d-flex align-items-center"
          :class="{ opened: openedAccordion === 6 }"
          @click="openAcordion(6)"
        >
          <div class="icon">
            <div
              class="circle-plus closed"
              :class="{ opened: openedAccordion === 6 }"
            >
              <div class="circle">
                <div class="horizontal"></div>
                <div class="vertical"></div>
              </div>
            </div>
          </div>

          Môžem si vybrať viacero modulov?
        </div>
        <div
          class="custom-accodrion-collapse-wrapper"
          ref="collapse-wrapper-a6"
        >
          <div class="custom-accodrion-collapse" ref="collapse-a6">
            <b-card-body>
              <b-card-text
                >Môžete. Ak chcete napr. v 9.A absolvovať modul F a v 7.B modul
                D, nie je to problém. Platí však, že jeden modul musí byť
                absolvovaný celý v jednej triede v jednom polroku.</b-card-text
              >
            </b-card-body>
          </div>
        </div>
      </div>

      <div class="custom-accodrion-card">
        <div
          class="custom-accodrion-button d-flex align-items-center"
          :class="{ opened: openedAccordion === 7 }"
          @click="openAcordion(7)"
        >
          <div class="icon">
            <div
              class="circle-plus closed"
              :class="{ opened: openedAccordion === 7 }"
            >
              <div class="circle">
                <div class="horizontal"></div>
                <div class="vertical"></div>
              </div>
            </div>
          </div>

          Dostanem po absolvovaní nejaký certifikát?
        </div>
        <div
          class="custom-accodrion-collapse-wrapper"
          ref="collapse-wrapper-a7"
        >
          <div class="custom-accodrion-collapse" ref="collapse-a7">
            <b-card-body>
              <b-card-text
                >Áno, žiaci aj vy dostanete certifikát o absolvovaní celého
                programu (iný druh diplomu, ako získavate na portáli za
                absolvovanie Série videí)</b-card-text
              >
            </b-card-body>
          </div>
        </div>
      </div>

      <div class="custom-accodrion-card">
        <div
          class="custom-accodrion-button d-flex align-items-center"
          :class="{ opened: openedAccordion === 8 }"
          @click="openAcordion(8)"
        >
          <div class="icon">
            <div
              class="circle-plus closed"
              :class="{ opened: openedAccordion === 8 }"
            >
              <div class="circle">
                <div class="horizontal"></div>
                <div class="vertical"></div>
              </div>
            </div>
          </div>

          Môžeme sa prihlásiť aj viacerí zo školy?
        </div>
        <div
          class="custom-accodrion-collapse-wrapper"
          ref="collapse-wrapper-a8"
        >
          <div class="custom-accodrion-collapse" ref="collapse-a8">
            <b-card-body>
              <b-card-text
                >Áno, prihlášky však budeme posudzovať osobitne.</b-card-text
              >
            </b-card-body>
          </div>
        </div>
      </div>

      <div class="custom-accodrion-card">
        <div
          class="custom-accodrion-button d-flex align-items-center"
          :class="{ opened: openedAccordion === 9 }"
          @click="openAcordion(9)"
        >
          <div class="icon">
            <div
              class="circle-plus closed"
              :class="{ opened: openedAccordion === 9 }"
            >
              <div class="circle">
                <div class="horizontal"></div>
                <div class="vertical"></div>
              </div>
            </div>
          </div>

          Potrebujem súhlas riaditeľa?
        </div>
        <div
          class="custom-accodrion-collapse-wrapper"
          ref="collapse-wrapper-a9"
        >
          <div class="custom-accodrion-collapse" ref="collapse-a9">
            <b-card-body>
              <b-card-text
                >V praxi sa stretávame s tým, že učitelia informujú vedenie
                školy o tom, že sa chcú zapojiť do programu, no záleží to od
                systému a pravidiel vašej školy.</b-card-text
              >
            </b-card-body>
          </div>
        </div>
      </div>

      <div class="custom-accodrion-card">
        <div
          class="custom-accodrion-button d-flex align-items-center"
          :class="{ opened: openedAccordion === 10 }"
          @click="openAcordion(10)"
        >
          <div class="icon">
            <div
              class="circle-plus closed"
              :class="{ opened: openedAccordion === 10 }"
            >
              <div class="circle">
                <div class="horizontal"></div>
                <div class="vertical"></div>
              </div>
            </div>
          </div>

          Je program akreditovaný?
        </div>
        <div
          class="custom-accodrion-collapse-wrapper"
          ref="collapse-wrapper-a10"
        >
          <div class="custom-accodrion-collapse" ref="collapse-a10">
            <b-card-body>
              <b-card-text
                >Momenátlne nie. V Zmudri však intenzívne pracujeme na tom, aby
                sme program v blízkej budúcnosti akreditovali ako inovačné
                vzdelávanie učiteľov. Certifikát za jeho absolvovanie si však
                ako učiteľ môžete
                <strong>zaradiť do svojho portfólia.</strong></b-card-text
              >
            </b-card-body>
          </div>
        </div>
      </div>

      <div class="custom-accodrion-card">
        <div
          class="custom-accodrion-button d-flex align-items-center"
          :class="{ opened: openedAccordion === 11 }"
          @click="openAcordion(11)"
        >
          <div class="icon">
            <div
              class="circle-plus closed"
              :class="{ opened: openedAccordion === 11 }"
            >
              <div class="circle">
                <div class="horizontal"></div>
                <div class="vertical"></div>
              </div>
            </div>
          </div>

          Pre aké školy a predmety je program vhodný?
        </div>
        <div
          class="custom-accodrion-collapse-wrapper"
          ref="collapse-wrapper-a11"
        >
          <div class="custom-accodrion-collapse" ref="collapse-a11">
            <b-card-body>
              <b-card-text>
                Program je určený pre žiakov od 8.ročníka základných škôl a
                všetky typy stredných škôl bez ohľadu na región Slovenska. Do
                programu sú zapojení učitelia rôznych predmetov - občianskej
                náuky, informatiky, etickej výchovy, ale aj iných predmetov. Je
                len na vás, na akom predmete program absolvujete.
              </b-card-text>
            </b-card-body>
          </div>
        </div>
      </div>

      <div class="custom-accodrion-card">
        <div
          class="custom-accodrion-button d-flex align-items-center"
          :class="{ opened: openedAccordion === 12 }"
          @click="openAcordion(12)"
        >
          <div class="icon">
            <div
              class="circle-plus closed"
              :class="{ opened: openedAccordion === 12 }"
            >
              <div class="circle">
                <div class="horizontal"></div>
                <div class="vertical"></div>
              </div>
            </div>
          </div>

          Môžem používať video kurzy aj mimo programu?
        </div>
        <div
          class="custom-accodrion-collapse-wrapper"
          ref="collapse-wrapper-a12"
        >
          <div class="custom-accodrion-collapse" ref="collapse-a12">
            <b-card-body>
              <b-card-text
                >Samozrejme. Video kurzy, ktoré sú súčasťou programu alebo aj
                tie, ktoré sú k dispozícii na portáli zmudri.sk môžete použiť
                podľa vlastných preferencií v triedach, kde program absolvujete
                alebo aj mimo nich.</b-card-text
              >
            </b-card-body>
          </div>
        </div>
      </div>

      <div class="custom-accodrion-card">
        <div
          class="custom-accodrion-button d-flex align-items-center"
          :class="{ opened: openedAccordion === 13 }"
          @click="openAcordion(13)"
        >
          <div class="icon">
            <div
              class="circle-plus closed"
              :class="{ opened: openedAccordion === 13 }"
            >
              <div class="circle">
                <div class="horizontal"></div>
                <div class="vertical"></div>
              </div>
            </div>
          </div>

          Už som sa do programu zapojil predtým. Môžem sa zapojiť opäť?
        </div>
        <div
          class="custom-accodrion-collapse-wrapper"
          ref="collapse-wrapper-a13"
        >
          <div class="custom-accodrion-collapse" ref="collapse-a13">
            <b-card-body>
              <b-card-text
                >Samozrejme, budeme veľmi radi. Môžete využiť
                <strong>ďalšie novinky, ktoré sme pridali</strong>, ako napr.
                séria workshopov na tému “Ako zlepšovať kritické myslenie vašich
                žiakov”, bezplatné papierové
                <a
                  href="https://zmudri.sk/kurz/prirucka-prezitia-na-divokom-webe"
                  target="_blank"
                  >“Príručky prežitia na divokom webe”</a
                >
                či nové interaktívne workshopy priamo u vás na škole. Môžete sa
                zapojiť s rovnakým alebo iným modulom.</b-card-text
              >
            </b-card-body>
          </div>
        </div>
      </div>

      <div class="custom-accodrion-card">
        <div
          class="custom-accodrion-button d-flex align-items-center"
          :class="{ opened: openedAccordion === 14 }"
          @click="openAcordion(14)"
        >
          <div class="icon">
            <div
              class="circle-plus closed"
              :class="{ opened: openedAccordion === 14 }"
            >
              <div class="circle">
                <div class="horizontal"></div>
                <div class="vertical"></div>
              </div>
            </div>
          </div>

          Ako prebieha výber?
        </div>
        <div
          class="custom-accodrion-collapse-wrapper"
          ref="collapse-wrapper-a14"
        >
          <div class="custom-accodrion-collapse" ref="collapse-a14">
            <b-card-body>
              <b-card-text>
                Po
                <a href="https://forms.gle/HUfBuWjxwmhdVPFy7" target="_blank">
                  vyplnení prihlášky
                </a>
                ju starostlivo posúdime a ozveme sa vám s rozhodnutím o prijatí
                / zamietnutí. Pri výbere budeme sledovať vašu motiváciu na účasť
                v programe. V prípade kladnej odpovede, ktorú sa dozviete v
                polovici decembra vás budeme informovať o ďalšom postupe.
              </b-card-text>
            </b-card-body>
          </div>
        </div>
      </div>

      <div class="custom-accodrion-card">
        <div
          class="custom-accodrion-button d-flex align-items-center"
          :class="{ opened: openedAccordion === 15 }"
          @click="openAcordion(15)"
        >
          <div class="icon">
            <div
              class="circle-plus closed"
              :class="{ opened: openedAccordion === 15 }"
            >
              <div class="circle">
                <div class="horizontal"></div>
                <div class="vertical"></div>
              </div>
            </div>
          </div>

          Ako program prebieha?
        </div>
        <div
          class="custom-accodrion-collapse-wrapper"
          ref="collapse-wrapper-a15"
        >
          <div class="custom-accodrion-collapse" ref="collapse-a15">
            <b-card-body>
              <b-card-text
                >Ak vás do programu prijmeme, postup bude
                nasledovný:</b-card-text
              >
              <ul>
                <li>
                  od polovice januára do začiatku februára s nami absolvujete
                  webinár o tom, ako používať naše materiály a metodiky a
                  prejdeme si praktické otázky fungovania.
                </li>
                <li>
                  začiatkom februára začnete s programom - so žiakmi vyplníte
                  vstupný dotazník a začnete využívať videá a materiály (poradie
                  videí v module si určujete sami).
                </li>
                <li>
                  v priebehu programu vám budeme neustále k dispozícii - mailom
                  či telefonicky v prípade otázok a nejasností.
                </li>
                <li>
                  budete mať možnosť vy ako učiteľ absolvovať vzdelávaciu sériu
                  workshopov o kritickom myslení.
                </li>
                <li>
                  po ukončení používania videí opäť absolvujete so žiakmi
                  postojový dotazník, aby sme vedeli porovnať ich výsledky.
                </li>
              </ul>
            </b-card-body>
          </div>
        </div>
      </div>

      <div class="custom-accodrion-card">
        <div
          class="custom-accodrion-button d-flex align-items-center"
          :class="{ opened: openedAccordion === 16 }"
          @click="openAcordion(16)"
        >
          <div class="icon">
            <div
              class="circle-plus closed"
              :class="{ opened: openedAccordion === 16 }"
            >
              <div class="circle">
                <div class="horizontal"></div>
                <div class="vertical"></div>
              </div>
            </div>
          </div>

          Sú miesta v programe obmedzené?
        </div>
        <div
          class="custom-accodrion-collapse-wrapper"
          ref="collapse-wrapper-a16"
        >
          <div class="custom-accodrion-collapse" ref="collapse-a16">
            <b-card-body>
              <b-card-text
                >Áno. Preto, prosím, venujte náležitú pozornosť prihláške, na
                základe ktorej rozhodneme o vašom prijatí do
                programu.</b-card-text
              >
            </b-card-body>
          </div>
        </div>
      </div>

      <div class="custom-accodrion-card">
        <div
          class="custom-accodrion-button d-flex align-items-center"
          :class="{ opened: openedAccordion === 17 }"
          @click="openAcordion(17)"
        >
          <div class="icon">
            <div
              class="circle-plus closed"
              :class="{ opened: openedAccordion === 17 }"
            >
              <div class="circle">
                <div class="horizontal"></div>
                <div class="vertical"></div>
              </div>
            </div>
          </div>

          Mám na vás konkrétnu otázku. Kde vás môžem kontaktovať?
        </div>
        <div
          class="custom-accodrion-collapse-wrapper"
          ref="collapse-wrapper-a17"
        >
          <div class="custom-accodrion-collapse" ref="collapse-a17">
            <b-card-body>
              <b-card-text
                >Pokojne nám napíšte na
                <a href="mailto:na skoly@zmudri.sk" target="_blank"
                  >skoly@zmudri.sk</a
                ></b-card-text
              >
            </b-card-body>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SectionTitleIcon from "/assets/icons/sectionTitleImg.svg?inline";

export default {
  components: {
    SectionTitleIcon,
  },
  data() {
    return {
      openedAccordion: null,
    };
  },
  methods: {
    openAcordion(e) {
      if (this.openedAccordion === e) {
        this.openedAccordion = null;
        this.$refs[`collapse-wrapper-a${e}`].style.height = `0px`;
      } else {
        if (this.openedAccordion)
          this.$refs[
            `collapse-wrapper-a${this.openedAccordion}`
          ].style.height = `0px`;
        this.openedAccordion = e;
        this.$refs[`collapse-wrapper-a${e}`].style.height = `${
          this.$refs[`collapse-a${e}`].clientHeight
        }px`;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.custom-accodrion-card {
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 16px;
  font-weight: 700;
  line-height: 40px;

  &:last-child {
    border-bottom: none;
  }

  .card-text,
  li {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: -0.05em;
    text-align: left;
    color: #2b2e4a;
    opacity: 0.6;
    a {
      text-decoration: underline !important;
    }
  }

  button {
    border: none;
    padding: 10px;
    box-shadow: none;
    background: white;

    font-size: 16px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;
  }
}

.custom-accodrion-button {
  cursor: pointer;

  &:hover {
    color: #1980ff;

    .icon {
      .vertical,
      .horizontal {
        background-color: rgba(0, 127, 255, 1) !important;
      }
    }
  }
}

.custom-accodrion-collapse-wrapper {
  overflow: hidden;
  width: 100%;
  height: 0px;
  transition: 0.3s;

  .custom-accodrion-collapse {
    width: 100%;
    height: fit-content;
  }
}

a {
  text-decoration: underline !important;
}

.title {
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;

  h1 {
    margin: auto 0 auto 10px;
  }

  @media only screen and (max-width: 575px) {
    text-align: center;

    img {
      width: 64px;
    }
    h1 {
      margin: 0 0 0 10px;
      font-size: 26px;
    }
  }
}
.opened {
  color: #1980ff;
}
.icon {
  position: relative;
  margin-right: 29px;
  width: 14px;
  height: 14px;
  .vertical {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2px;
    height: 14px;
    background-color: black;
    transition: 0.2s;
  }
  .horizontal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 14px;
    height: 2px;
    background-color: black;
  }
  .opened .vertical {
    opacity: 0;
  }
  .opened .horizontal {
    background-color: rgba(0, 127, 255, 1) !important;
  }
}
.container {
  padding: 100px 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

a.link {
  font-size: inherit;
  color: #0165ff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
</style>
