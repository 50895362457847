<template>
  <div class="all">
    <div class="background"></div>
    <div class="container" id="moduly">
      <div class="title">
        <SectionTitleIcon />
        <h1 class="title-text">{{ $t("do-skol.thematical-modules") }}</h1>
      </div>
      <div class="row tematicke-moduly d-none d-lg-flex">
        <div class="col-5 col-xl-4 sections-title">
          <p class="name">{{ $t("do-skol.module-name") }}</p>
          <div class="section-border">
            <div
              class="d-flex section"
              :class="m.id === selectedModule ? 'selected' : ' '"
              v-for="m in modules"
              :key="m.id"
              @click="selectedModule = m.id"
            >
              <img
                :src="
                  require(`/assets/icons/${m.letter.toLowerCase()}Section.svg`)
                "
              />
              <div class="d-flex">
                <p class="letter">{{ m.letter }}</p>
                <p>{{ m.name }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-7 col-xl-8 row module-wrapper">
          <div class="col-12 col-xl-6 section-content">
            <p class="name">{{ $t("video-courses-in-phone") }}</p>
            <div
              class="section-for first-section d-flex"
              v-for="section in modules[selectedModule - 1].section_left"
              :key="section.id"
              :class="section.url === 'none' ? 'unactive-url' : ' '"
              @click="clickedLink(section.url)"
            >
              <div class="badge">{{ section.id }}.</div>
              <div class="d-flex inner-div section-text">
                <p>{{ section.text }}</p>
                <div class="align-items-center play-button">
                  <PlayIcon />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-6 section-content">
            <div class="helper d-none d-xl-block"></div>
            <div
              class="section-for d-flex section"
              v-for="section in modules[selectedModule - 1].section_right"
              :key="section.id"
              :class="section.url === 'none' ? 'unactive-url' : ' '"
              @click="clickedLink(section.url)"
            >
              <div class="badge">{{ section.id + 5 }}.</div>
              <div class="d-flex inner-div section-text">
                <p>{{ section.text }}</p>
                <div class="align-items-center play-button">
                  <PlayIcon />
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="selectedModule === 4 || selectedModule === 5"
            class="banner"
          >
            <img src="/infoBanner.png" />
            <i18n path="do-skol.paper-offer" tag="p">
              <a
                href="https://zmudri.sk/kurz/prirucka-prezitia-na-divokom-webe"
                target="_blank"
                >"{{ $t("do-skol.wild-web-survival-guide") }}"</a
              >
            </i18n>
          </div>
        </div>
      </div>
      <div class="d-lg-none acordions">
        <div class="accordion">
          <b-card
            no-body
            id="accordion1"
            class="mb-1"
            v-for="m in modules"
            :key="m.id"
          >
            <button
              class="d-flex align-items-center"
              :class="m.id === active ? 'selected-accordion' : ' '"
              block
              variant="info"
              @click="
                toogleAccordion(`accordion-${m.id}`);
                setActive(m.id);
              "
            >
              {{ m.letter + ")   " + m.name }}
            </button>
            <b-collapse
              accordion="my-accordion"
              :id="`accordion-${m.id}`"
              role="tabpanel"
              class="list"
            >
              <b-card-body class="justify-content-center p-0">
                <div>
                  <div
                    class="accordions-content d-flex"
                    v-for="section in modules[selectedModule - 1].section_left"
                    :key="section.letter"
                    :class="section.url === 'none' ? 'unactive-url' : ' '"
                    @click="clickedLink(section.url)"
                  >
                    <div class="badge">{{ section.id }}.</div>
                    <div class="d-flex inner-div">
                      <p>{{ section.text }}</p>
                      <div class="align-items-center play-button">
                        <PlayIcon />
                      </div>
                    </div>
                  </div>

                  <div
                    class="accordions-content d-flex"
                    v-for="section in modules[selectedModule - 1].section_right"
                    :key="section.letter"
                    :class="section.url === 'none' ? 'unactive-url' : ' '"
                    @click="clickedLink(section.url)"
                  >
                    <div class="badge">{{ section.id + 5 }}.</div>
                    <div class="d-flex inner-div">
                      <p>{{ section.text }}</p>
                      <div class="align-items-center play-button">
                        <PlayIcon />
                      </div>
                    </div>
                  </div>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import modules from "/assets/json/module.json";
import SectionTitleIcon from "/assets/icons/sectionTitleImg.svg?inline";
import PlayIcon from "/assets/icons/play.svg?inline";

export default {
  components: {
    SectionTitleIcon,
    PlayIcon,
  },
  data() {
    return {
      modules,
      selectedModule: 1,
      active: "0",
    };
  },
  methods: {
    clickedLink(e) {
      e != "none" ? window.open(e) : "";
    },
    toogleAccordion(index) {
      this.$root.$emit("bv::toggle::collapse", index);
    },
    setActive(e) {
      this.active === e ? (this.active = "0") : (this.active = e);
      this.selectedModule = e;
    },
  },
};
</script>
<style lang="scss" scoped>
.unactive-url {
  opacity: 0.3;
}
.all {
  position: relative;
  height: fit-content;
  padding: 200px 0;
  left: 0;
  @media only screen and (max-width: 575px) {
    padding: 0;
  }
  @media only screen and (min-width: 1900px) {
    height: calc(100% - 10vw);
    top: 5vw;
  }

  @media only screen and (min-width: 2500px) {
    height: calc(100% - 5vw);
    top: 2.5vw;
  }
}
.background {
  position: absolute;
  width: 150%;
  height: calc(100% - 25vw);
  left: -25%;
  top: 12.5vw;
  z-index: -1;
  background: #2b2e4a;
  transform: matrix(-1, -0.09, -0.09, 1, 0, 0);

  @media only screen and (min-width: 1200px) {
    height: calc(100% - 15vw);
    top: 7.5vw;
  }

  @media only screen and (min-width: 2800px) {
    height: calc(100% - 10vw);
    top: 5vw;
  }
}

.container {
  height: fit-content;
  padding: 100px 0;
  text-align: center;
  @media only screen and (max-width: 575px) {
    padding: 150px 0;
  }
}
.title {
  display: inline-flex;
  margin-bottom: 40px;

  h1 {
    margin: auto 0 auto 10px;
    color: rgba(255, 255, 255, 1);
  }
  @media only screen and (max-width: 575px) {
    text-align: center;
    h1 {
      margin: 0 0 0 10px;
      font-size: 26px;
    }
  }
}
.acordions {
  .card {
    background: #2b2e4a;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 0;

    .card-text,
    li {
      font-size: 16px;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: -0.05em;
      text-align: left;
      color: #2b2e4a;
      opacity: 0.6;
    }
    button {
      border: none;
      padding: 10px;
      box-shadow: none;
      background: #2b2e4a;

      color: white;
      opacity: 0.4;
      font-size: 16px;
      font-weight: 700;
      line-height: 40px;
      text-align: left;
    }
    .selected-accordion {
      opacity: 1;
    }
  }
  .card:last-child {
    border: none;
  }
  .list {
    .accordions-content {
      justify-content: left;
      align-items: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      margin: 20px 8px;
      min-height: 65px;
      .inner-div {
        width: 100%;
      }
      .badge {
        border-radius: 50%;
        color: white;
        background: rgba(255, 255, 255, 0.4);
        width: 32px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
      }
      p {
        color: #ffffff;
        opacity: 0.6;

        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin: 0 5px;
        text-align: left;
        display: flex;
        align-items: center;
      }
      .play-button {
        background: rgba(255, 170, 0, 0.3);
        width: 36px;
        height: 36px;
        border-radius: 50%;
        margin-left: auto;
        display: flex;
        flex-shrink: 0;
        justify-content: center;
      }
    }
    .accordions-content:hover {
      p {
        opacity: 1;
        font-weight: 400;
        text-decoration: underline;
      }
      cursor: pointer;
    }
  }
}

.name {
  text-align: left;
  font-weight: 700;
  font-size: 1rem;
  text-transform: uppercase;
  line-height: 1.5rem;
  color: rgba(255, 255, 255, 0.25);
}

.tematicke-moduly {
  width: 100%;
  .sections-title {
    .section-border {
      border-right: 1px solid rgba(255, 255, 255, 0.4);

      .section {
        align-items: center;
        min-height: 80px;

        &:hover,
        &.selected {
          div {
            cursor: pointer;
            p {
              opacity: 1;
              font-weight: 500;
            }
          }
        }
        &.selected {
          div {
            p {
              font-weight: 700;
            }
          }
        }

        img {
          width: 56px;
          height: 56px;
          margin: 5px;
        }
        div {
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          width: 75%;
          height: 100%;
          margin: 5px 5px 5px 15px;
          .letter {
            margin-right: 10px;
          }
          p {
            font-size: 16px;
            font-weight: 500;
            line-height: 23px;
            letter-spacing: 0em;
            padding: 16px 0 0;
            text-align: left;

            color: rgba(255, 255, 255, 1);
            opacity: 0.2;

            &:nth-child(2) {
              width: 82%;
            }
          }
        }
        &:last-child {
          div {
            border-bottom: none;
          }
        }
      }
    }
  }
  .banner {
    width: 100%;
    height: 60px;
    background: rgba(246, 250, 252, 0.05);
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 20px 15px 0;

    border: 1px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    border-radius: 8px;
    p {
      margin: 0 10px 0 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: rgba(255, 255, 255, 0.6);
      a {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: rgba(255, 255, 255, 0.6);
        text-decoration: underline;
        cursor: pointer;
      }
    }
    img {
      margin: auto 10px;
    }
  }
  .section-content {
    .helper {
      height: 24px;
    }
    .first-section:last-child {
      margin: 0px 8px 0;
    }

    .section-for {
      justify-content: left;
      align-items: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      margin: 20px 8px;
      min-height: 65px;
      .inner-div {
        width: 100%;
      }
      .badge {
        border-radius: 50%;
        color: white;
        background: rgba(255, 255, 255, 0.1);
        width: 30px;
        height: 30px;
        display: flex;
        flex: 0 0 30px;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
      }
      p {
        color: #ffffff;
        opacity: 0.6;

        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin: 0 5px;
      }
      .play-button {
        background: rgba(255, 170, 0, 0.1);
        width: 36px;
        height: 36px;
        border-radius: 50%;
        margin-left: auto;
        display: flex;
        flex-shrink: 0;
        justify-content: center;
      }
    }
    .section-for:hover {
      cursor: pointer;
      p {
        opacity: 1;
        font-weight: 400;
        text-decoration: underline;
      }
      .badge {
        background: rgba(255, 255, 255, 0.4);
      }
      .play-button {
        background: rgba(255, 170, 0, 0.3);
      }
    }
  }

  p {
    text-align: left;
    color: white;
  }
}
.module-wrapper {
  height: fit-content;
}
.section-text {
  align-items: center;
}
</style>
