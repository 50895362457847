<template>
  <div class="container">
    <div class="title">
      <SectionTitleIcon />
      <h1 class="title-text">Partneri programu</h1>
    </div>

    <div
      class="logos-container d-flex flex-wrap justify-content-center align-items-center"
    >
      <div
        v-for="partner of partners"
        :key="partner.name"
        class="logo-container d-flex justify-content-center align-items-center"
      >
        <img
          :src="partner.imgLink"
          :alt="partner.name + ' - Logo'"
          class="logo"
          :class="partner.classes"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitleIcon from "/assets/icons/sectionTitleImg.svg?inline";
export default {
  components: {
    SectionTitleIcon,
  },
  data() {
    return {
      partners: [
        {
          imgLink:
            "https://res.cloudinary.com/zmudri/image/upload/v1635339883/online%20platform/Partner%20logos/nadacia_orange_logo_ufl229_iuyj7a_xudu1y.jpg",
          name: "Nadácia Orange",
          classes: [],
        },
        {
          imgLink:
            "https://res.cloudinary.com/zmudri/image/upload/v1635340295/online%20platform/Partner%20logos/nadacia-pontis-logo_diwmjy.png",
          name: "Nadácia Pontis",
          classes: [],
        },
        {
          imgLink:
            "https://res.cloudinary.com/zmudri/image/upload/v1624974850/online%20platform/Partner%20logos/nadacia-slovenskej-sporitelne_vfgbg5.png",
          name: "Nadácia Slovenskej sporiteľne",
          classes: [],
        },
        {
          imgLink:
            "https://res.cloudinary.com/zmudri/image/upload/v1635339960/online%20platform/Partner%20logos/ferova-nadacia-o2_s77b5t.png",
          name: "Férová nadácia O2",
          classes: [],
        },
        {
          imgLink:
            "https://res.cloudinary.com/zmudri/image/upload/v1635164161/online%20platform/Partner%20logos/msvvssr-iuventa_huvqis.jpg",
          name: "Ministerstvo školstva, vedy, výskumu a športu Slovenskej republiky + Iuventa",
          classes: [],
        },
        {
          imgLink:
            "https://res.cloudinary.com/zmudri/image/upload/v1668254149/online%20platform/Partner%20logos/nadacia-zse-logo_fur1jo.png",
          name: "Nadácia ZSE",
          classes: [],
        },
        {
          imgLink:
            "https://res.cloudinary.com/zmudri/image/upload/v1674140190/online%20platform/Partner%20logos/csob-logo_zbrafm.jpg",
          name: "ČSOB",
          classes: [],
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.container {
  margin: 100px auto;
  margin-bottom: 0;
  padding-bottom: 0;
  text-align: center;

  .title {
    display: inline-flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 40px;
    h1 {
      margin: auto 0 auto 10px;
    }

    @media only screen and (max-width: 575px) {
      text-align: center;
      img {
        width: 64px;
      }
      h1 {
        margin: 0 0 0 10px;
        font-size: 26px;
      }
    }
  }
}

$max-width: 170px;
$max-height: 100px;
$max-width-l: 200px;
$max-height-l: 130px;
$max-width-xl: 255px;
$max-height-xl: 150px;

.logos-container {
  row-gap: 20px;
  column-gap: 40px;
  margin-bottom: 50px;

  @media only screen and (min-width: 1200px) {
    row-gap: 40px;
    margin-bottom: 100px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.logo-container {
  width: $max-width-xl;
  height: $max-height-xl;
}

.logo {
  width: auto;
  max-width: $max-width;
  height: auto;
  max-height: $max-height;

  @media only screen and (min-width: 1680px) {
    max-width: $max-width-l;
    max-height: $max-height-l;
  }
}

.logo-scribo {
  height: 120px;
}
</style>
