<template>
  <div class="content">
    <DoSkolNavbar class="navbar" />
    <DoSkolUciteliaVpred />
    <DoSkolOPrograme />
    <DoSkolPriebehProgramu />
    <DoSkolVyhodyProgramu />
    <DoSkolTematickeModuly />
    <DoSkolTematickeModulySlovesnko />
    <DoSkolZapojeneSkoly />
    <DoSkolSwiper />
    <DoSkolFAQ />
    <DoSkolPartneriProgramu />
    <DoSkolNapisaliONas />
  </div>
</template>
<script>
import DoSkolNavbar from "/components/DoSkol/DoSkolNavbar";
import DoSkolUciteliaVpred from "/components/DoSkol/DoSkolUciteliaVpred";
import DoSkolOPrograme from "/components/DoSkol/DoSkolOPrograme";
import DoSkolPriebehProgramu from "/components/DoSkol/DoSkolPriebehProgramu";
import DoSkolVyhodyProgramu from "/components/DoSkol/DoSkolVyhodyProgramu";
import DoSkolTematickeModuly from "/components/DoSkol/DoSkolTematickeModuly";
import DoSkolFAQ from "/components/DoSkol/DoSkolFAQ";
import DoSkolTematickeModulySlovesnko from "/components/DoSkol/DoSkolTematickeModulySlovensko";
import DoSkolNapisaliONas from "/components/DoSkol/DoSkolNapisaliONas";
import DoSkolPartneriProgramu from "/components/DoSkol/DoSkolPartneriProgramu";
import DoSkolSwiper from "/components/DoSkol/DoSkolSwiper";
import DoSkolZapojeneSkoly from "/components/DoSkol/DoSkolZapojeneSkoly";

export default {
  components: {
    DoSkolNavbar,
    DoSkolUciteliaVpred,
    DoSkolOPrograme,
    DoSkolPriebehProgramu,
    DoSkolVyhodyProgramu,
    DoSkolTematickeModuly,
    DoSkolFAQ,
    DoSkolTematickeModulySlovesnko,
    DoSkolNapisaliONas,
    DoSkolPartneriProgramu,
    DoSkolSwiper,
    DoSkolZapojeneSkoly,
  },
};
</script>

<style scoped lang="scss">
.navbar {
  position: fixed;
  top: 0;
  z-index: 2000;
  padding: 0px;
}
.content {
  padding-top: 67px;
}
</style>
